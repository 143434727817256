<template>
  <div class="entries">
    <section>
      <h1 class="title">60 Minutes Guided Pomodoro on Clubhouse</h1>
      <p class="topics">Sun | Mon | Tue | Wed | Fri - 6pm pst | 9pm est</p>
      <p>I decided to start "60 Minutes Guided Pomodoro" because I am currently studying computer science fundamentals and preparing for technical interviews. For procrastinators like me, spending time on tough subjects like those is the last thing you want to do. I needed a way to hold myself accountable, so I decided to use Clubhouse.</p>
      <p>This is open to procrastinators and non-procrastinators studying any topic, as well as anyone who benefits from having a co-working space and a more structured study session.</p>
      <p>For more information, check out the google documents linked below. For the most up-to-date calendar, please check this <a class="title" href="https://calendar.google.com/calendar/embed?src=c_1hk5or335upuik9tcrp11p5ins%40group.calendar.google.com&ctz=America%2FLos_Angeles" target="_blank" rel="noopener noreferrer"> calendar</a>.</p>
    </section>
    <section class="has-img">
      <a href="https://docs.google.com/document/d/11Jb1R-8eWMxxcOOh9K-exLEcvt4GzVaBF8XHaos82tA/edit?usp=sharing" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/schedule.png" alt="schedule document"></a>
      <a href="https://docs.google.com/document/d/1t271S0jAzRsxnwi0weKyiDOFAK_vl0PnYsx1VqQRrOw/edit?usp=sharing" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/study-plan.png" alt="study plan document"></a>
    </section>
  </div>
</template>

<style scoped>
img {
  height: 300px;
  width: 210px;
  margin-top: 50px;
}
.entries {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
section {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.has-img {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 50px;
}

.content, .jounal-content, .project-content {
  min-height: calc(80vh - 8em);
  padding: 4em;
  max-width: 70%;
  margin: 0 auto;
}
@media only screen and (max-width: 780px) {
  .content {
    max-width: 80%;
  }
}
@media only screen and (max-width: 650px) {
  .content {
    padding: 4rem 1rem;
  }
}
p {
  position: relative;
  line-height: 2.1rem;
  font-family: var(--text-font);
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

.journal-header {
  /* border-top: solid var(--main-color) 5px; */
  font-family: var(--header-font);
}
.topics {
  font-family: var(--header-font);
  font-size: 1.1rem;
  color: var(--bg);
  background-color: var(--main-color);
  padding: 5px;
}
.bold {
  font-weight: bolder;
}
.title {
  font-family: var(--theme-font);
  text-align: center;
}
.topics {
  font-family: var(--header-font);
  font-size: 1.1rem;
  color: var(--bg);
  background-color: var(--main-color);
  padding: 5px;
  text-align: center;
}
</style>